import React, { Fragment, useState, useEffect } from "react";
import RILightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Lightbox = ({ images = [], photoSrc = "", isOpen = false, onClose }) => {
  const [photoIndex, setPhotoIndex] = useState(0)

  useEffect(() => {
    setPhotoIndex(determinePhotoIndex(images, photoSrc));
  }, [images, photoSrc])

  function determinePhotoIndex(images, photoSrc) {
    const index = images.findIndex(image => image.src === photoSrc);
    return index >= 0 ? index : 0;
  }

  return (
    <Fragment>
      {isOpen && (
        <RILightbox
          mainSrc={images[photoIndex].src}
          mainSrcThumbnail={images[photoIndex].srcThumbnail}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          nextSrcThumbnail={images[(photoIndex + 1) % images.length].srcThumbnail}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          prevSrcThumbnail={images[(photoIndex + images.length - 1) % images.length].srcThumbnail}
          onCloseRequest={() => {
            onClose();
          }}
          imageTitle={images[photoIndex].title || images[photoIndex].description || ""}
          imageCaption={images[photoIndex].description || ""}
          animationDuration={200}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </Fragment>
  );
};

export default Lightbox